import { getRequest, postRequest } from '@/utills/axiosFrontend'
import { AlertsTableProps } from './interface'
import dayjs from 'dayjs'

export async function callDBFilters(
  subscription_id?: string | null,
  resource_group?: string | null,
  resource?: string | null,
  table_name?: string | null
) {
  try {
    const response = await getRequest(`/api/databricks/filters`, {
      params: {
        subscription_id,
        resource_group,
        resource,
        table_name,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      return response.error
    }
  } catch (error) {
    throw error
  }
}

export async function callWorkSpaceExpenseApi(filters: { [key: string]: string | null }, currency: string) {
  try {
    const response = await postRequest(`/api/databricks/resource/expense-cluster-job`, { ...filters, currency })
    if (response.status === 200) {
      return response.data
    } else {
      return response.error
    }
  } catch (error) {
    throw error
  }
}

export async function callClusterApi(filters: { [key: string]: string | null }) {
  try {
    const response = await postRequest(`/api/databricks/resource/cluster`, filters)
    if (response.status === 200) {
      return response.data
    } else {
      return response.error
    }
  } catch (error) {
    throw error
  }
}

export async function callJobsApi(filters: { [key: string]: string | null }) {
  try {
    const response = await postRequest(`/api/databricks/resource/jobs`, filters)
    if (response.status === 200) {
      return response.data
    } else {
      return response.error
    }
  } catch (error) {
    throw error
  }
}

export async function callExpenseTable(
  resource_name: string,
  table_name: string,
  start_date: string,
  end_date: string,
  currency: string
) {
  try {
    const response = await getRequest(`/api/databricks/resource/expense-table`, {
      params: {
        start_date,
        end_date,
        resource_name,
        table_name,
        currency,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      return response.error
    }
  } catch (error) {
    throw error
  }
}

export async function callIdleClusters(data: AlertsTableProps) {
  try {
    const response = await postRequest(`/api/databricks/resource/idle-clusters-table`, data)
    if (response.status === 200) {
      return response.data
    } else {
      return response.error
    }
  } catch (error) {
    throw error
  }
}

export async function callAutoScalingClusters(data: AlertsTableProps) {
  try {
    const response = await postRequest(`/api/databricks/resource/auto-scaling-clusters-table`, data)
    if (response.status === 200) {
      return response.data
    } else {
      return response.error
    }
  } catch (error) {
    throw error
  }
}

export async function callLongestRunningJobs(data: AlertsTableProps) {
  try {
    const response = await postRequest(`/api/databricks/resource/longest-runnings-jobs`, data)
    if (response.status === 200) {
      return response.data
    } else {
      return response.error
    }
  } catch (error) {
    throw error
  }
}

export async function callUploadApi(filters: { [key: string]: string | null }, currency: string, page: string) {
  try {
    const response = await postRequest(`/api/common/upload`, { ...filters, currency, page })
    if (response.status === 200) {
      return response.data
    } else {
      return response.error
    }
  } catch (error) {
    throw error
  }
}

export async function downloadCSVApi(blobName: string, page: string) {
  try {
    const response = await getRequest(`/api/common/download?blobName=${blobName}&page=${page}`)
    if (response.status === 200) {
      return response.data
    } else {
      return response.error
    }
  } catch (error) {
    throw error
  }
}

export async function callExpenseChart(
  resource_name: string,
  table_name: string,
  start_date: string,
  end_date: string,
  currency: string
) {
  try {
    const response = await getRequest(`/api/databricks/resource/expense-chart`, {
      params: {
        start_date,
        end_date,
        resource_name,
        table_name,
        currency,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      return response.error
    }
  } catch (error) {
    throw error
  }
}

export async function callClustersList(
  resource_name: string,
  table_name: string,
  start_date: string,
  end_date: string
) {
  try {
    const response = await getRequest(`/api/databricks/resource/cluster/list`, {
      params: {
        start_date,
        end_date,
        resource_name,
        table_name,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      return response.error
    }
  } catch (error) {
    throw error
  }
}

export async function callClustersDetail(
  resource_name: string,
  table_name: string,
  start_date: string,
  end_date: string,
  cluster_name: string,
  currency: string
) {
  try {
    const response = await getRequest(`/api/databricks/resource/cluster/detail`, {
      params: {
        start_date,
        end_date,
        resource_name,
        table_name,
        cluster_name,
        currency,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      return response.error
    }
  } catch (error) {
    throw error
  }
}

export async function callTopClusters(
  resource_name: string,
  table_name: string,
  start_date: string,
  end_date: string,
  currency: string
) {
  try {
    const response = await getRequest(`/api/databricks/cluster/top-clusters`, {
      params: {
        start_date,
        end_date,
        resource_name,
        table_name,
        currency,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      return response.error
    }
  } catch (error) {
    throw error
  }
}

export async function callClusterTable(data: AlertsTableProps) {
  try {
    const response = await postRequest(`/api/databricks/cluster/cluster-table`, data)
    if (response.status === 200) {
      return response.data
    } else {
      return response.error
    }
  } catch (error) {
    throw error
  }
}

export async function callJobMetricsApi(filters: { [key: string]: string | null }, currency: string, clusterRow: any) {
  try {
    const response = await postRequest(`/api/databricks/cluster/job-metrics`, {
      start_date: filters.start_date,
      end_date: filters.end_date,
      table_name: filters.table_name,
      currency: currency,
      cluster_id: clusterRow.cluster_id,
    })
    if (response.status === 200) {
      return response.data
    } else {
      return response.error
    }
  } catch (error) {
    throw error
  }
}

export async function callCpuAPi(job_name: string, start_date: string, end_date: string, instance_ip?: any) {
  try {
    const response = await postRequest(`/api/databricks/cluster/cpu-usage`, {
      start_date: dayjs(start_date).format('MM/DD/YYYY'),
      end_date: dayjs(end_date).format('MM/DD/YYYY'),
      job_name: job_name,
      instance_ip,
    })
    if (response.status === 200) {
      return response.data
    } else {
      return response.error
    }
  } catch (error) {
    throw error
  }
}

export async function callClusterConfigAPi(table_name: string | null, cluster_id: string) {
  try {
    const response = await getRequest(`/api/databricks/cluster/cluster-config`, {
      params: { table_name, cluster_id },
    })
    if (response.status === 200) {
      return response.data
    } else {
      return response.error
    }
  } catch (error) {
    throw error
  }
}

export async function callUsageMetricsApi(job_name: string | null, start_date: string | null) {
  try {
    const response = await postRequest(`/api/databricks/cluster/usage-metrics`, {
      job_name,
      start_date,
    })
    if (response.status === 200) {
      return response.data
    } else {
      return response.error
    }
  } catch (error) {
    throw error
  }
}

export async function callMemoryUsageApi(start_date: string, end_date: string, job_name: string) {
  try {
    const response = await postRequest(`/api/databricks/cluster/memory-usage`, { start_date, end_date, job_name })
    if (response.status === 200) {
      return response.data
    } else {
      return response.error
    }
  } catch (error) {
    throw error
  }
}

export async function callDiskSpaceApi(start_date: string, end_date: string, job_name: string) {
  try {
    const response = await postRequest(`/api/databricks/cluster/disk-space`, { start_date, end_date, job_name })
    if (response.status === 200) {
      return response.data
    } else {
      return response.error
    }
  } catch (error) {
    throw error
  }
}

export async function fetchIpList(job_name: string | null) {
  try {
    const response = await getRequest(`/api/databricks/cluster/instance-ip-list`, {
      params: {
        job_name,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      return response.error
    }
  } catch (error) {
    throw error
  }
}

import { combineReducers, configureStore } from '@reduxjs/toolkit'
import userSlice from '../app/users/slice'
import applicationSlice from '../app/slice'
import homeSlice from '../app/home/slice'
import resourceSlice from '../app/resource/slice'
import databricksSlice from '../app/databricks/slice'
import pipelinesSlice from '../app/pipelines/slice'
import ec2 from '../app/ec2/slice'
import vmSlice from '../app/vm/slice'
import aiSlice from '../app/recommendation/ai/slice'
import descriptiveSlice from '../app/recommendation/descriptive/slice'
import { persistReducer, persistStore } from 'redux-persist'
//import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import sessionStorage from 'redux-persist/es/storage/session' // use sessionStorage
import thunk from 'redux-thunk'

const persistConfig = {
  key: 'root',
  storage: sessionStorage,
  whitelist: ['databricks', 'vm', 'ec2', 'ai'], // only 'users' and 'application' will be persisted
}

const rootReducer = combineReducers({
  users: userSlice,
  application: applicationSlice,
  home: homeSlice,
  resource: resourceSlice,
  databricks: databricksSlice,
  pipelines: pipelinesSlice,
  ec2: ec2,
  vm: vmSlice,
  descriptive: descriptiveSlice,
  ai: aiSlice,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)
// create a slice

// config the store
const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
})

export const persistor = persistStore(store)

export default store
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

'use client'
import { createSlice } from '@reduxjs/toolkit'
import { VM_FILTERS } from '@/app/vm/constants'

export interface VMState {
  filters: { [key: string]: string }
  serviceRow: { [key: string]: string }
}

// Define an initial state
const initialState: VMState = {
  filters: VM_FILTERS,
  serviceRow: {},
}

const vmSlice = createSlice({
  name: 'vm',
  initialState,
  reducers: {
    setTopFilters: (state, action) => {
      state.filters = action.payload
    },
    setServiceRow: (state, action) => {
      state.serviceRow = action.payload
    },
  },
})
export const { setTopFilters, setServiceRow } = vmSlice.actions

export default vmSlice.reducer

// axiosFrontend.js
import axios, {AxiosRequestConfig, AxiosResponse} from 'axios'

const axiosFrontend = axios.create({
    baseURL: process.env.NEXTAUTH_URL,
    headers: {
        'Content-Type': 'application/json',
    },
})

const findError = (error: any) => {
    const apiStatus = error.response.status
    switch (apiStatus) {
        case 400:
            return 'Bad request.'
        case 401:
            return 'Unauthenticated user.'
        case 403:
            return "You don't have permission to access the request."
        case 404:
            return 'API url not found.'
        case 503:
            return 'Service Unavailable.'
        default:
            return 'Something went wrong.'
    }
}
// Add a response interceptor for successful responses
axiosFrontend.interceptors.response.use(
    (response: AxiosResponse) => {
        // Do something with successful response data
        //console.log('Response received - interceptors :', response);
        return response
    },
    (error) => {
        // Do something with response error
        if (error?.response?.data?.custom) {
            return Promise.reject({
                error: error.response.status,
                message: error.response.data.message,
                config: error.config,
            })
        }
        return Promise.reject({error: error?.response?.status, message: findError(error), config: error?.config})
    }
)

// Generic function for making GET requests
export const getRequest = async <T>(url: string, config?: AxiosRequestConfig): Promise<any> => {
    const response = await axiosFrontend.get<T>(url, config)
    if(url?.includes('download')) {
        return {data: response.data, status: response.status, statusText: response.statusText}
    }
    else return {...response.data, status: response.status, statusText: response.statusText}
}

// Generic function for making POST requests
export const postRequest = async <T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<any> => {
    const response = await axiosFrontend.post<T>(url, data, config)
    return {...response.data, status: response.status, statusText: response.statusText}
}

// Generic function for making PUT requests
export const putRequest = async <T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<any> => {
    const response = await axiosFrontend.put<T>(url, data, config)
    return {...response.data, status: response.status, statusText: response.statusText}
}

// Generic function for making DELETE  requests
export const deleteRequest = async <T>(url: string, config?: AxiosRequestConfig): Promise<any> => {
    const response = await axiosFrontend.delete<T>(url, config)
    //return response.data
    return { status: response.status, ...response.data, statusText: response.statusText }
  }


// Alter defaults after instance has been created
export default axiosFrontend

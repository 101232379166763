import axiosFrontend from "@/utills/axiosFrontend";

export async function getUsers() {
    try {
        const response = await axiosFrontend.get('https://jsonplaceholder.typicode.com/users');
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Failed to fetch Users');
        }
    } catch (error) {
        console.log(error);
    }
}

export async function getUser(id: number) {
    const response = await axiosFrontend.get(`/api/v1/users/${id}`);
    return response.data;
}

export async function addUser(data: any) {
    try {
        const response = await axiosFrontend.post('/api/v1/users', data);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Failed to add user');
        }
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export const updateUserData = async (userId: number, userData: any) => {
    try {
        const response = await axiosFrontend.patch(`/api/v1/users/${userId}`, userData);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Failed to update user');
        }
    } catch (error) {
        throw error;
    }
};

export const deleteUserData = async (userId: number) => {
    try {
        const response = await axiosFrontend.delete(`/api/v1/users/${userId}`);
        if (response.status === 200) {
            return {success: true};
        } else {
            throw new Error('Failed to delete user');
        }
    } catch (error) {
        throw error;
    }
};

import React from 'react'
import { Avatar, Dropdown, Layout, MenuProps, Space } from 'antd'
import { LogoutOutlined } from '@ant-design/icons'
import { signOut, useSession } from 'next-auth/react'
import Image from 'next/image'
import Link from 'next/link'
import { useDispatch, useSelector } from 'react-redux'
import InputSelect from '@/components/form/inputSelect'
import { persistor, RootState } from '@/store/store'
import { CURRENCY_TYPES } from '@/utills/constant'
import { usePathname } from 'next/navigation'
import theme from '../../theme/themeConfig'

const { Header } = Layout

const HeaderComponent: React.FC = () => {
  const { data } = useSession()
  const user = data?.user
  const dispatch = useDispatch()
  const currency = useSelector((state: RootState) => state.application.currency)
  const hideCurrency = usePathname().includes('/un-auth')
  const items: MenuProps['items'] = [
    {
      label: 'Logout',
      key: 'logout',
      icon: <LogoutOutlined />,
    },
  ]

  const handleMenuClick: MenuProps['onClick'] = async () => {
    await persistor.purge() // clear redux persist store
    await signOut({ callbackUrl: '/', redirect: true })
  }

  const menuProps = {
    items,
    onClick: handleMenuClick,
  }

  const handleChange = (value: string) => {
    dispatch({ type: 'application/setCurrency', payload: value })
  }

  return (
    <Header className="site-header">
      <Link href={'/home'}>
        <div style={{ display: 'flex' }}>
          <Image
            alt={'logo'}
            src={'/images/logo.svg'}
            style={{
              width: 'auto',
              height: 'auto',
            }}
            priority={true}
            loading={'eager'}
            height="90"
            width="90"
            className={'logo-image'}
          />
        </div>
      </Link>
      <div className="header-right flex items-center">
        <Space wrap>
          {!hideCurrency && (
            <InputSelect
              style={{ width: 55 }}
              initValue={currency}
              name={'period'}
              label={''}
              options={CURRENCY_TYPES}
              rules={[{ required: true, message: '' }]}
              onSelect={(value): any => {
                handleChange(value)
              }}
            />
          )}
          <Dropdown arrow menu={menuProps} trigger={['click']}>
            <Space style={{ cursor: 'pointer' }}>
              {user?.image ? (
                <Avatar src={user?.image} />
              ) : (
                <Avatar style={{ backgroundColor: theme.token?.colorPrimary }}>
                  {user?.email?.charAt(0).toUpperCase()}
                </Avatar>
              )}
              <span>{user?.name}</span>
              <span title={user?.email?.charAt(0).toUpperCase()}> </span>
            </Space>
          </Dropdown>
        </Space>
      </div>
    </Header>
  )
}

export default HeaderComponent

export const homeIcon = (
  <svg width="23" height="23" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.82658 4.25247L2.53824 9.75507C1.99898 10.227 2.36054 11.0651 3.10337 11.0651C3.5572 11.0651 3.92509 11.4049 3.92509 11.824V15.327C3.92509 18.3407 3.92509 19.8476 4.9387 20.7838C5.95231 21.72 7.58369 21.72 10.8464 21.72H13.1536C16.4163 21.72 18.0477 21.72 19.0613 20.7838C20.0749 19.8476 20.0749 18.3407 20.0749 15.327V11.824C20.0749 11.4049 20.4428 11.0651 20.8966 11.0651C21.6395 11.0651 22.001 10.2269 21.4618 9.75507L15.1734 4.25247C13.6707 2.93751 12.9193 2.28003 12 2.28003C11.0807 2.28003 10.3293 2.93751 8.82658 4.25247Z"
      stroke="#667085"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M12.0007 16.322H12.0104" stroke="#667085" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export const recommendIcon = (
  <svg width="23" height="23" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="10" stroke="#404041" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M12.8638 7.72209L13.7437 9.49644C13.8637 9.74344 14.1837 9.98035 14.4536 10.0257L16.0485 10.2929C17.0684 10.4643 17.3083 11.2103 16.5734 11.9462L15.3335 13.1964C15.1236 13.4081 15.0086 13.8164 15.0736 14.1087L15.4285 15.6562C15.7085 16.8812 15.0636 17.355 13.9887 16.7148L12.4939 15.8226C12.2239 15.6613 11.7789 15.6613 11.504 15.8226L10.0091 16.7148C8.93925 17.355 8.28932 16.8761 8.56929 15.6562L8.92425 14.1087C8.98925 13.8164 8.87426 13.4081 8.66428 13.1964L7.42442 11.9462C6.6945 11.2103 6.92947 10.4643 7.94936 10.2929L9.54419 10.0257C9.80916 9.98035 10.1291 9.74344 10.2491 9.49644L11.129 7.72209C11.609 6.7593 12.3889 6.7593 12.8638 7.72209Z"
      stroke="#404041"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const resourceIcon = (
  <svg width="23" height="23" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z"
      stroke="#667085"
      strokeWidth="1.5"
    />
    <path d="M2.5 12H21.5" stroke="#667085" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13 7L17 7" stroke="#667085" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <circle cx="8.25" cy="7" r="1.25" stroke="#667085" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <circle
      cx="8.25"
      cy="17"
      r="1.25"
      stroke="#667085"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M13 17L17 17" stroke="#667085" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export const databricksIcon = (
  <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_3853_6712)">
      <path
        className="fill"
        d="M0.633301 9.456L7.99997 13.602L14.6126 9.902V11.3753L7.99997 15.108L1.01063 11.1347L0.633967 11.34V11.8533L7.99997 16L15.3666 11.8547V8.97667L15.0233 8.77067L7.99997 12.7453L1.42197 8.97667V7.50333L7.99997 11.2033L15.3666 7.058V4.21333L15.0233 4.008L7.99997 7.98267L1.76463 4.454L7.99997 0.925333L13.1733 3.83733L13.6186 3.56333V3.186L7.99997 0L0.633301 4.18V4.66L7.99997 8.80467L14.6126 5.10467V6.61133L7.99997 10.3467L1.01063 6.37333L0.633967 6.57867L0.633301 9.456Z"
        fill="#404041"
      />
    </g>
    <defs>
      <clipPath id="clip0_3853_6712">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export const ec2Icon = (
  <svg width="23" height="23" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4 12C4 8.22876 4 6.34315 5.17157 5.17157C6.34315 4 8.22876 4 12 4C15.7712 4 17.6569 4 18.8284 5.17157C20 6.34315 20 8.22876 20 12C20 15.7712 20 17.6569 18.8284 18.8284C17.6569 20 15.7712 20 12 20C8.22876 20 6.34315 20 5.17157 18.8284C4 17.6569 4 15.7712 4 12Z"
      stroke="#667085"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M9.5 2V4" stroke="#667085" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14.5 2V4" stroke="#667085" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9.5 20V22" stroke="#667085" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14.5 20V22" stroke="#667085" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13 9L9 13" stroke="#667085" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15 13L13 15" stroke="#667085" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M22 14.5L20 14.5" stroke="#667085" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4 9.5L2 9.5" stroke="#667085" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4 14.5L2 14.5" stroke="#667085" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M22 9.5L20 9.5" stroke="#667085" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export const vmIcon = (
  <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.0029 2H10.0062C6.72443 2 5.08355 2 3.92039 2.81382C3.49006 3.1149 3.11577 3.48891 2.81445 3.91891C2 5.08116 2 6.72077 2 10C2 13.2792 2 14.9188 2.81445 16.0811C3.11577 16.5111 3.49006 16.8851 3.92039 17.1862C5.08355 18 6.72443 18 10.0062 18H14.0093C17.2911 18 18.932 18 20.0951 17.1862C20.5254 16.8851 20.8997 16.5111 21.2011 16.0811C21.8156 15.2042 21.9663 14.0941 22 13"
      stroke="#667085"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18 9.71428V11M18 9.71428C16.8432 9.71428 15.8241 9.14608 15.2263 8.28331M18 9.71428C19.1568 9.71428 20.1759 9.14608 20.7737 8.28331M18 3.28571C19.1569 3.28571 20.1761 3.854 20.7738 4.71688M18 3.28571C16.8431 3.28571 15.8239 3.854 15.2262 4.71688M18 3.28571V2M22 3.92857L20.7738 4.71688M14.0004 9.07143L15.2263 8.28331M14 3.92857L15.2262 4.71688M21.9996 9.07143L20.7737 8.28331M20.7738 4.71688C21.1273 5.22711 21.3333 5.84035 21.3333 6.5C21.3333 7.15973 21.1272 7.77304 20.7737 8.28331M15.2262 4.71688C14.8727 5.22711 14.6667 5.84035 14.6667 6.5C14.6667 7.15973 14.8728 7.77304 15.2263 8.28331"
      stroke="#667085"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M11 15H13" stroke="#667085" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 18V22" stroke="#667085" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8 22H16" stroke="#667085" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export const datasourceIcon = (
  <svg width="23" height="23" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20 5.7C20 7.19117 16.4183 8.4 12 8.4C7.58172 8.4 4 7.19117 4 5.7M20 5.7C20 4.20883 16.4183 3 12 3C7.58172 3 4 4.20883 4 5.7M20 5.7V18.3C20 19.794 16.4444 21 12 21C7.55556 21 4 19.794 4 18.3V5.7M20 12C20 13.494 16.4444 14.7 12 14.7C7.55556 14.7 4 13.494 4 12"
      stroke="#404041"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const pipelineIcon = (
  <svg width="16" height="22" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      className="fill"
      d="M9.26315 0.818182C9.26315 0.366313 9.8692 0 10 0H11C11.1308 0 11.7368 0.366313 11.7368 0.818182V5.18182C11.7368 5.63369 11.1308 6 11 6H10C9.8692 6 9.26315 5.63369 9.26315 5.18182V0.818182Z"
      fill="#667085"
    />
    <path
      className="fill"
      d="M4 16.8182C4 16.3663 4.60604 16 4.73685 16H5.73685C5.86765 16 6.47369 16.3663 6.47369 16.8182V21.1818C6.47369 21.6337 5.86765 22 5.73685 22H4.73685C4.60604 22 4 21.6337 4 21.1818V16.8182Z"
      fill="#667085"
    />
    <path
      className="fill"
      d="M3 10C3 9.6988 3.35346 9.45459 3.78947 9.45459H12.2105C12.6465 9.45459 13 9.6988 13 10V12.4995C13 12.8008 12.6465 13.045 12.2105 13.045H3.78947C3.35346 13.045 3 12.8008 3 12.4995V10Z"
      fill="#667085"
    />
    <path
      className="fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5 3.5H11V2H15C15.5523 2 16 2.44772 16 3V11C16 11.5523 15.5523 12 15 12H11V10.5H14.5V3.5Z"
      fill="#667085"
    />
    <path
      className="fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 18.5L5 18.5L5 20L1 20C0.447715 20 3.91405e-08 19.5523 8.74228e-08 19L7.43094e-07 11.5C7.91376e-07 10.9477 0.447716 10.5 1 10.5L5 10.5L5 12L1.5 12L1.5 18.5Z"
      fill="#667085"
    />
  </svg>
)

export const adminIcon = (
  <svg width="23" height="23" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19 20V18.2222C19 17.2792 18.6313 16.3749 17.9749 15.7081C17.3185 15.0413 16.4283 14.6667 15.5 14.6667H8.5C7.57174 14.6667 6.6815 15.0413 6.02513 15.7081C5.36875 16.3749 5 17.2792 5 18.2222V20M15.5 7.55556C15.5 9.51923 13.933 11.1111 12 11.1111C10.067 11.1111 8.5 9.51923 8.5 7.55556C8.5 5.59188 10.067 4 12 4C13.933 4 15.5 5.59188 15.5 7.55556Z"
      stroke="#404041"
      strokeLinejoin="round"
      strokeLinecap="round"
    />
  </svg>
)

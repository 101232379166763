'use client'
import React, { useEffect } from 'react'
import NavBarComponent from '@/components/navBar'
import HeaderComponent from '@/components/header'
import '../../app/globals.css'
import './css/custom-style.css'
import { App, ConfigProvider, Layout } from 'antd'
import theme from '../../theme/themeConfig'
import NextBreadcrumb from '@/components/common/bread-crumb'
import { usePathname } from 'next/navigation'
import { signOut, useSession } from 'next-auth/react'
import { persistor } from '@/store/store'

const { Content } = Layout

const AfterLoginLayout = ({ children }: React.PropsWithChildren) => {
  const { data: session } = useSession()
  const paths = ['/datasource', '/databricks', '/pipelines', '/ec2', '/vm', '/recommendation']
  const pathname = usePathname()
  const hideBreadcrumb = paths.some((path) => pathname.includes(path))
  const hidePadding = pathname.includes('/datasource')
  const hideSidebar = pathname.includes('/un-auth')

  useEffect(() => {
    if (session === null) {
      persistor.purge() // clear redux persist store
      signOut({ callbackUrl: '/', redirect: true })
    }
  }, [session])

  return (
    <ConfigProvider theme={theme}>
      <App>
        <Layout style={{ height: '100vh', backgroundColor: theme.components?.Layout?.bodyBg }}>
          <HeaderComponent />
          {!hideSidebar && (
            <Layout hasSider>
              <NavBarComponent />
              <Layout>
                {/* {!hideBreadcrumb && <NextBreadcrumb />} */}
                <Content style={{ overflow: 'auto' }} className={hidePadding ? '' : 'after-login-main-content'}>
                  {children}
                </Content>
              </Layout>
            </Layout>
          )}
          {hideSidebar && <Content className={hidePadding ? '' : 'after-login-main-content'}>{children}</Content>}
        </Layout>
      </App>
    </ConfigProvider>
  )
}

export default AfterLoginLayout

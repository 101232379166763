'use client'
import { createSlice } from '@reduxjs/toolkit'
import { SERVICES } from '@/utills/constant'

export interface AIState {
  tableRow: { [key: string]: string }
  operation: string
  service: string
}

// Define an initial state
const initialState: AIState = {
  tableRow: {},
  operation: 'downgrade',
  service: SERVICES.DATABRICKS,
}

const aiSlice = createSlice({
  name: 'ai',
  initialState,
  reducers: {
    setTableRow: (state, action) => {
      state.tableRow = action.payload
    },
    setOperation: (state, action) => {
      state.operation = action.payload
    },
    setService: (state, action) => {
      state.service = action.payload
    },
  },
})
export const { setTableRow, setOperation, setService } = aiSlice.actions

export default aiSlice.reducer

import { getRequest, postRequest } from '@/utills/axiosFrontend'

export async function callResourceCost(period: string, subscriptions: string[], currency: string, email: string) {
  try {
    const response = await postRequest('/api/home/resource', {
      time_range: period,
      subscriptions: subscriptions,
      currency,
      email
    })
    if (response.status === 200) {
      return response.data
    } else {
      return response.error
    }
  } catch (error) {
    throw error
  }
}

export async function getCategoryCost(period: string, subscriptions: string[], currency: string, email: string) {
  try {
    const response = await postRequest(`/api/home/get-category-cost`, {
      time_range: period,
      subscriptions: subscriptions,
      currency,
      email
    })
    if (response.status === 200) {
      return response.data
    } else {
      return response.error
    }
  } catch (error) {
    throw error
  }
}

export async function callCardCost(period: string, currency: string, email: string | undefined | null) {
  try {
    const response = await getRequest(`/api/home/get-card-cost`, {
      params: {
        time_range: period,
        currency,
        email: email,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      return response.error
    }
  } catch (error) {
    throw error
  }
}

export async function callCategoryExpense(period: string, currency: string, email: string | undefined | null, type: string) {
  try {
    const response = await getRequest(`/api/home/get-category-expense`, {
      params: {
        time_range: period,
        currency,
        email: email,
        type: type,
      },
    })
    if (response.status === 200) {
      return response.data
    } else {
      return response.error
    }
  } catch (error) {
    throw error
  }
}

'use client'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { callCardCost } from './service'
import { KPIProps } from './interface'

export interface HomeState {
  subscriptions: { [key: string]: string }[]
  timePeriod: string
  kpiData: KPIProps
  loading: boolean
  kpiError: string | null
}

// Define an initial state
const initialState: HomeState = {
  subscriptions: [],
  timePeriod: 'monthly',
  kpiData: { current: 0, previous: 0, subscription_costs: [] },
  loading: true,
  kpiError: null,
}

export const fetchTopFiveAccounts = createAsyncThunk(
  'home/fetchTopFiveAccounts',
  async ({ period, currency, email }: { period: string; currency: string; email: string | undefined | null }) => {
    try {
      const data = await callCardCost(period, currency, email)
      return data
    } catch (error) {
      throw error
    }
  }
)

const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    setSubscriptions: (state, action) => {
      state.subscriptions = action.payload
    },
    setTimePeriod: (state, action) => {
      state.timePeriod = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTopFiveAccounts.pending, (state) => {
        state.loading = true
        state.kpiError = null
      })
      .addCase(fetchTopFiveAccounts.fulfilled, (state: HomeState, action: any) => {
        state.kpiData = action.payload
        state.loading = false
      })
      .addCase(fetchTopFiveAccounts.rejected, (state: HomeState, action: any) => {
        state.loading = false
        state.kpiError = action?.error?.message
      })
  },
})
export const { setSubscriptions, setTimePeriod } = homeSlice.actions

export default homeSlice.reducer

import dayjs from 'dayjs'
import { DATE_FORMAT } from '@/utills/constant'

export const PIPE_FILTERS = {
  pipelineId: '',
  pipelineName: [],
  owner: [],
  project: [],
  sourceCode: [],
  startDate: dayjs().startOf('month').format(DATE_FORMAT),
  endDate: dayjs().format(DATE_FORMAT),
}

export const RUN_FILTERS = {
  runName: [],
  result: [],
  state: [],
  triggeredBy: [],
}

export interface PipeUploadParams {
  filters: any
  request_params_str: any
}

export interface RUNS_TABLE_PARAMS {
  runName: string
  result: string
  errorType: string
  startTime: string
  endTime: null
  duration: null
  triggeredBy: string
  expense: null
  state: string
  firedAlerts: number
  count: number
  clusterId: string
}

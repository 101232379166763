'use client'
import { createSlice } from '@reduxjs/toolkit'

export interface VMState {
  ruleRow: { [key: string]: string | string[] }
  isArchive: boolean
  isEdit: boolean
  showDrawer: boolean
  tableReload: boolean
}

// Define an initial state
const initialState: VMState = {
  ruleRow: {},
  isArchive: false,
  isEdit: false,
  showDrawer: false,
  tableReload: false,
}

const descriptiveSlice = createSlice({
  name: 'descriptive',
  initialState,
  reducers: {
    setRuleRow: (state, action) => {
      state.ruleRow = action.payload
    },
    setIsArchive: (state, action) => {
      state.isArchive = action.payload
    },
    setIsEdit: (state, action) => {
      state.isEdit = action.payload
    },
    setShowDrawer: (state, action) => {
      state.showDrawer = action.payload
    },
    setTableReload: (state, action) => {
      state.tableReload = action.payload
    },
  },
})
export const { setRuleRow, setIsArchive, setIsEdit, setShowDrawer, setTableReload } = descriptiveSlice.actions

export default descriptiveSlice.reducer

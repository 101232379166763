// SearchableSelect.tsx

import React, { useEffect, useRef } from 'react'
import { Form, FormInstance, Select, SelectProps } from 'antd'

interface SearchableSelectProps {
  options: { value: string; label: string }[]
  onSelect: (value: number | string) => void
  isSearchable?: boolean
  name: string
  label?: string
  initValue?: string | null
  rules: { required: boolean; message: string }[]
  placeholder?: string
  layout?: string
  allowClear?: boolean
}

const InputSelect: React.FC<SearchableSelectProps & SelectProps> = ({
  name,
  label,
  initValue,
  rules,
  options,
  onSelect,
  isSearchable = false,
  allowClear = false,
  placeholder,
  layout,
  ...selectProps
}) => {
  const formRef = useRef<FormInstance>(null)

  useEffect(() => {
    if (formRef.current) {
      formRef.current.setFieldsValue({
        [name]: initValue ? initValue : null,
      })
    }
  }, [initValue])

  return (
    <Form ref={formRef} layout={'vertical'}>
      <Form.Item style={{ margin: 0 }} label={label} name={name} rules={rules} initialValue={initValue}>
        <Select
          style={{ backgroundColor: 'white', borderRadius: '24px' }}
          showSearch={isSearchable}
          placeholder={placeholder}
          filterOption={(input, option: any) => {
            if (typeof option.label === 'string') {
              return option.label.toLowerCase().includes(input.toLowerCase())
            }
            return false
          }}
          onChange={onSelect}
          {...selectProps}
          options={options}
          allowClear={allowClear}
        />
      </Form.Item>
    </Form>
  )
}

export default InputSelect

'use client'

import { createSlice } from '@reduxjs/toolkit'

export interface AppState {
  currency: string
  page: string
  //Need to decide to add or not in store below details from nextAuth session object
  user: {
    email: string
    name: string
  }
}

// Define an initial state
const initialState: AppState = {
  currency: 'USD',
  page: 'home',
  user: {
    email: '',
    name: '',
  },
}

const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload
    },
    setCurrency: (state, action) => {
      state.currency = action.payload
    },
    setUser: (state, action) => {
      state.user = action.payload
    },
  },
})
export const { setPage, setCurrency, setUser } = applicationSlice.actions

export default applicationSlice.reducer

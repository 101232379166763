import { ListMeta, Options } from '@/interfaces/common'
import dayjs from 'dayjs'

export const LIST_META: ListMeta = {
  total: 0,
  count: 0,
  per_page: 10,
  current_page: 0,
  total_pages: 0,
}

export const AZURE = 'Azure'
export const AWS = 'AWS'
export const API_URL = process.env['CHAT_LLM']
export const CLIENT_ID_INFO =
  'It is a unique identifier for your application, used to authenticate and authorize access to Azure resources.'
export const TENANT_ID_INFO =
  'It is a unique identifier for your Azure Active Directory (AAD) tenant, representing your organizations instance within Azure.'
export const SUB_ID_INFO =
  'It is a GUID (Globally Unique Identifier) that serves as a unique reference for your Azure account.'
export const SUB_NAME_INFO =
  'User-defined name that helps you identify your account easily, especially if you have multiple subscriptions associated with your Azure account.'
export const SECRET_INFO =
  'It is a confidential string that serves as a key for the application to authenticate itself with Azure Active Directory and obtain access tokens for resource authorization.'
export const ACC_NAME_INFO =
  'User-defined name that helps you identify your account easily, especially if you have multiple subscriptions associated with your AWS account.'
export const ACC_ID_INFO =
  'It is a GUID (Globally Unique Identifier) that serves as a unique reference for your AWS account.'
export const ACC_URL =
  'Location in Amazon S3 where AWS stores detailed billing reports for your AWS account'
export const CHART_COLORS = {
  //resource: ['#8A0BC1', '#DE9FF9', '#CE6EF7', '#BD3EF4', '#8E44AD', '#C495D5'],
  resource: [
    '#DB8C16',
    '#16DBCC',
    '#FF82AC',
    '#6AB8FF',
    '#FFBB38',
    '#5030E5',
    '#972BEC',
    '#4CA1AF',
    '#A43931',
    '#FC00FF',
    '#BE93C5',
  ],
}

export const TIME_PERIOD: Options[] = [
  { value: 'daily', label: 'Daily' },
  { value: 'weekly', label: 'Weekly' },
  { value: 'monthly', label: 'Monthly' },
  { value: 'quarterly', label: 'Quarterly' },
  // { value: 'half', label: 'Half Yearly' },
]

export const TIME_PERIOD_LABELS: { [key: string]: string } = {
  daily: 'Day',
  weekly: 'Week',
  monthly: 'Month',
  quarterly: 'Quarter',
  half: 'Half Year',
}

export const CURRENCY_TYPES: Options[] = [
  { value: 'USD', label: '$' },
  { value: 'INR', label: '₹' },
  { value: 'EURO', label: '€' },
]
export const EXPENSE_CATEGORY: Options[] = [
  { value: 'subscription', label: 'Subscription' },
  { value: 'meter_category', label: 'Meter Category' },
]
export const DATE_FORMAT = 'MM/DD/YYYY'
export const RESOURCE_FILTERS: { [key: string]: any } = {
  env: [],
  project: [],
  region: [],
  accountName: [],
  meterCategory: [],
  subscriptionName: [],
  resourceGroup: [],
}
export const CATEGORY_FILTERS = {
  project: 'Project',
  resourceGroup: 'Resource Group',
  meterCategory: 'Meter Category',
  subscriptionName: 'Subscription',
  accountName: 'Account Name',
  region: 'Resource Location',
}
//export const BACKGROUND_GRADIENT = 'linear-gradient(to right, #D1F7F1 0%, #F9D5D5 100%, white 100%)'
export const BACKGROUND_GRADIENT = 'linear-gradient(to right, #E5F5F2 0%, #F4E8E7 100%, white 100%)'

export const DBX_FILTERS = {
  subscription_id: null,
  resource_group: null,
  resource_name: null,
  table_name: null,
  start_date: dayjs().startOf('month').format(DATE_FORMAT),
  end_date: dayjs().format(DATE_FORMAT),
}

//Used for static referance not related to DB data
export const DOWNLOAD_REFERENCE = {
  DBX_RO_BILLING: 'DBX_RO_BILLING',
  DBX_CO_CLUSTERS: 'DBX_CO_CLUSTERS',
  DBX_CO_JOBS: 'DBX_CO_JOBS',
  RESOURCE_PAGE: 'RESOURCE_PAGE',
  RESOURCE_TABLE: 'RESOURCE_TABLE',
  PIPELINES_TABLE: 'PIPELINES_TABLE',
  RUNS_TABLE: 'RUNS_TABLE',
  EC2_REC_TABLE: 'EC2_REC_TABLE',
  VM_REC_TABLE: 'VM_REC_TABLE',
  DBX_REC_TABLE: 'DBX_REC_TABLE',
}
export const PAGE_SIZE_OPTIONS = [5, 10, 20, 50, 100]
export const DEFAULT_PAGINATION = {
  pagination: {
    current: 1,
    pageSize: 5,
  },
}

export const SERVICES = {
  DATABRICKS: 'Databricks',
  VM: 'VM',
  EC2: 'EC2',
}

'use client'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { DBX_FILTERS } from '@/utills/constant'
import { fetchIpList } from './service'

export interface DatabricksState {
  filters: { [key: string]: string | null }
  clusterRow: { [key: string]: string }
  ipList: string[]
}

// Define an initial state
const initialState: DatabricksState = {
  filters: DBX_FILTERS,
  clusterRow: {},
  ipList: [],
}

export const fetchInstanceIps = createAsyncThunk(
  'databricks/fetchInstanceIps',
  async ({ job_name }: { job_name: string }) => {
    try {
      const data = await fetchIpList(job_name)
      return data
    } catch (error) {
      throw error
    }
  }
)

const databricksSlice = createSlice({
  name: 'databricks',
  initialState,
  reducers: {
    setFilters: (state, action) => {
      state.filters = action.payload
    },
    setClusterRow: (state, action) => {
      state.clusterRow = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInstanceIps.pending, (state) => {})
      .addCase(fetchInstanceIps.fulfilled, (state: DatabricksState, action: any) => {
        state.ipList = action.payload
      })
      .addCase(fetchInstanceIps.rejected, (state: DatabricksState, action: any) => {})
  },
})
export const { setFilters } = databricksSlice.actions

export default databricksSlice.reducer

import type { ThemeConfig } from 'antd'

export const BORDER_RADIUS = 15
export const DEFAULT_BG = '#E2EAF3'
const theme: ThemeConfig = {
  token: {
    fontSize: 14,
    fontFamily: 'Poppins',
    //colorTextHeading: '#3aa866',
    colorTextBase: '#1c1b1b',
    colorPrimary: '#1E31F6',
    colorInfo: '#fe5000',
  },
  components: {
    Table: {
      //headerBg: '#EFF2F7',
      //headerColor: '#FFFFFF',
      fontWeightStrong: 600,
      padding: 7,
      lineHeight: 1.3,
      headerBorderRadius: 0,
      headerSortHoverBg: '',
      headerSortActiveBg: '',
      headerFilterHoverBg: '',
      fontSize: 14,
    },
    Breadcrumb: {
      lastItemColor: '#fe5000',
    },
    Layout: {
      bodyBg: '#EFF2F7',
    },
    DatePicker: {
      cellWidth: 30,
      borderRadius: BORDER_RADIUS,
    },
    Select: {
      borderRadius: BORDER_RADIUS,
      colorBorder: '#d8e1ef',
      selectorBg: '#CED7E500',
    },
    Button: {
      defaultBg: '#CED7E500',
      defaultColor: 'rgba(0,0,0,0.64)',
      defaultBorderColor: '#CED7E5',
      borderRadius: BORDER_RADIUS,
    },
    Popover: {
      colorBgElevated: DEFAULT_BG,
      boxShadowSecondary: '0 0 10px 0 rgba(0,0,0,0.1)',
    },
    Radio: {
      radioSize: 13,
      controlHeight: 25,
      borderRadius: BORDER_RADIUS,
      fontSize: 14,
      buttonPaddingInline: 10,
    },
    Tabs: {
      borderRadius: BORDER_RADIUS,
      itemSelectedColor: '#38393DFF',
    },
    Tag: {
      borderRadiusSM: 10,
    },
  },
}

export default theme

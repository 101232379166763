'use client'
import { createSlice } from '@reduxjs/toolkit'
import { PIPE_FILTERS, RUN_FILTERS } from '@/app/pipelines/interface'

export interface DatabricksState {
  pipeline: {
    row: { [key: string]: string }
    filters: {
      pipelineName: string[]
      owner: string[]
      project: string[]
      sourceCode: string[]
      startDate: string
      endDate: string
    }
  }
  runs: {
    row: { [key: string]: string }
    filters: {
      runName: string[]
      result: string[]
      state: string[]
      triggeredBy: string[]
      //startDate: string
      //endDate: string
    }
  }
}

// Define an initial state
const initialState: DatabricksState = {
  pipeline: {
    row: {},
    filters: PIPE_FILTERS,
  },
  runs: {
    row: {},
    filters: RUN_FILTERS,
  },
}

const pipelinesSlice = createSlice({
  name: 'pipelines',
  initialState,
  reducers: {
    setPipelineRow: (state, action) => {
      state.pipeline.row = action.payload
    },
    setPipeFilters: (state, action) => {
      state.pipeline.filters = action.payload
    },
    setRunsRow: (state, action) => {
      state.runs.row = action.payload
    },
    setRunsFilters: (state, action) => {
      state.runs.filters = action.payload
    },
  },
})
export const { setPipelineRow, setPipeFilters, setRunsRow, setRunsFilters } = pipelinesSlice.actions

export default pipelinesSlice.reducer

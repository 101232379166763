'use client'
import { createSlice } from '@reduxjs/toolkit'
import { EC2_FILTERS } from '@/app/ec2/constants'

export interface Ec2State {
  filters: { [key: string]: string }
  serviceRow: { [key: string]: string }
}

// Define an initial state
const initialState: Ec2State = {
  filters: EC2_FILTERS,
  serviceRow: {},
}

const ec2Slice = createSlice({
  name: 'ec2',
  initialState,
  reducers: {
    setTopFilters: (state, action) => {
      state.filters = action.payload
    },
    setServiceRow: (state, action) => {
      state.serviceRow = action.payload
    },
  },
})
export const { setTopFilters, setServiceRow } = ec2Slice.actions

export default ec2Slice.reducer

import dayjs from 'dayjs'
import { DATE_FORMAT } from '@/utills/constant'

export const VM_FILTERS = {
  subscription: '',
  resource_group: '',
  virtual_machine: '',
  table_name: '',
  start_date: dayjs().startOf('month').format(DATE_FORMAT),
  end_date: dayjs().format(DATE_FORMAT),
}
'use client'
import { createSlice } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import { DATE_FORMAT, RESOURCE_FILTERS } from '@/utills/constant'

export interface ResourceState {
  dates: { startDate: string; endDate: string }
  filters: { [key: string]: string[] }
}

// Define an initial state
const initialState: ResourceState = {
  dates: { startDate: dayjs().startOf('month').format(DATE_FORMAT), endDate: dayjs().format(DATE_FORMAT) },
  filters: RESOURCE_FILTERS,
}

const resourceSlice = createSlice({
  name: 'resource',
  initialState,
  reducers: {
    setDates: (state, action) => {
      state.dates = action.payload
    },
    setFilters: (state, action) => {
      state.filters = action.payload
    },
  },
})
export const { setDates } = resourceSlice.actions

export default resourceSlice.reducer
